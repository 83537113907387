import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuroraComponent } from './components/aurora/aurora.component';
import { RecirculatingComponent } from './components/recirculating/recirculating.component';
import { BatchComponent } from './components/batch/batch.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { ContactComponent } from './components/contact/contact.component';
import { SupportComponent } from './components/support/support.component';
import { InfluencerComponent } from './components/contacts/influencer/influencer.component';
import { InstallerComponent } from './components/contacts/installer/installer.component';
import { DistributerComponent } from './components/contacts/distributer/distributer.component';
import { ElixirComponent } from './components/elixir/elixir.component';
import { FluxComponent } from './components/flux/flux.component';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/',
  },
  {
    path: '',
    component: MainComponent,
  },
  {
    path: 'main',
    redirectTo: '/'
  },
  {
    path: 'aurora',
    component: AuroraComponent,
  },
  {
    path: 'elixir',
    component: ElixirComponent,
  },
  {
    path: 'recirculating',
    component: RecirculatingComponent,
  },
  {
    path: 'batch',
    component: BatchComponent,
  },
  {
    path: 'cloud',
    component: CloudComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'influencer',
    component: InfluencerComponent,
  },
  {
    path: 'installer',
    component: InstallerComponent,
  },
  {
    path: 'distributer',
    component: DistributerComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'flux',
    component: FluxComponent,
  },
  {
    path: 'guide',
    component: SupportComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: 'https://nuravine.com/Aurora-User-Guide.pdf'
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
